import * as React from "react";
import { useState, ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }: { children: ReactNode }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className="bg-white mx-auto max-w-7xl">
      <Header mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
