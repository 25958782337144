import * as React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2"></div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-xs leading-5 text-stone-500">
            &copy; {new Date().getFullYear()} 도서출판 장안. All rights
            reserved.
            <br />
          </p>
          <p className="text-center text-xs leading-5 text-stone-500">
            대표: 김선우 | 사업자등록번호: 558-95-01595
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
